import { ApiResponseData, PaymentOrder, StatementOrder } from '/~/types/api'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { Order } from '/~/composables/order'

export class ScheduledPayment {
  number = ''
  scheduledAt = ''
  subtotal = 0
  payeeId = ''
  reference = ''
  payee = {}
  scheduledPaymentMethods = []
  isLoading = false
  orderType = null
  order = {}

  constructor(raw = {}) {
    this.order = raw?.order ?? {}
    this.orderType = raw?.orderType ?? null

    Object.keys(this.order).forEach((key) => {
      if (typeof this[key] === 'undefined') {
        return
      }

      const type = typeof this[key]

      switch (type) {
        case 'number': {
          this[key] = Number(this.order[key] ?? this[key])
          break
        }
        default: {
          this[key] = this.order[key] ?? this[key]
        }
      }
    })
  }

  get apiBasePath() {
    return `/v3/${
      this.isStatementOrder ? 'statement-orders' : 'payment-orders'
    }`
  }

  get fees() {
    return this.order.fees ?? {}
  }

  set fees(fees) {
    this.order.fees = fees
  }

  get processingFees() {
    return (this.fees.items ?? []).reduce((amount, item) => {
      return amount + (item.label === 'Processing Fee' ? item.amount : 0)
    }, 0)
  }

  get programFees() {
    return (this.fees.items ?? []).reduce((amount, item) => {
      return amount + (item.label === 'Program Fee' ? item.amount : 0)
    }, 0)
  }

  get totalFees() {
    return this.fees.total ?? 0
  }

  get isPaymentOrder() {
    return this.orderType === FlowType.payment
  }

  get isStatementOrder() {
    return this.orderType === FlowType.statement
  }

  async delete() {
    this.isLoading = true

    try {
      await this.update({
        scheduledAt: null,
      })

      emitter.emit('scheduled-payments:payment-deleted', this.number)
    } catch (error) {
      console.error('scheduled-payment', error)
      throw error
    } finally {
      this.isLoading = false
    }
  }

  async update(payload) {
    this.isLoading = true

    if (payload.subtotal !== this.subtotal) {
      const response = await api.get<
        ApiResponseData<PaymentOrder | StatementOrder>
      >(`${this.apiBasePath}/${this.number}`)
      const payment = new Order(response.data ?? {})

      if (!payload.paymentSources) {
        payload.paymentSources = (payment.scheduledPaymentMethods ?? []).map(
          (method) => {
            return {
              paymentMethodId: method.id,
              amount: method.amount,
              securityToken: null,
              verificationCode: null,
              points: null,
            }
          }
        )
      }

      if (!payload.paymentDestinations) {
        payload.paymentDestinations = [
          {
            payeeId: this.payeeId || this.payee.id,
            amount: this.subtotal?.toString(),
          },
        ]
      }
    }

    try {
      await api.put(`${this.apiBasePath}/${this.number}`, payload).then(() => {
        Object.assign(this, payload)
        emitter.emit('scheduled-payments:payment-updated', this.number)
      })
    } catch (error) {
      console.error('scheduled-payment', error)
      throw error
    } finally {
      this.isLoading = false
    }
  }
}

export default ScheduledPayment
